@import 'vars'

#advantages 
  > .section-img
    right: 55%
    transform: translateX(50%)
    bottom: 0rem
    top: 10rem
    z-index: -1

    @media (max-width: $md)
      display: none

    img
      object-fit: contain
      height: auto

  > .content 
    width: 100%
    display: flex
    flex-direction: column

    @media (max-width: $md)
      padding: 0 $mobile-wrapper-padding

.advantages-board
  display: grid
  grid-template-columns: repeat(4, 2fr)
  grid-row-gap: 2rem
  flex-grow: 1

  @media (max-width: $md)
    display: flex
    flex-direction: column
    gap: 0

    .advantage-card 
      margin-bottom: 2rem

  .right
    grid-column: 4 / 5 




